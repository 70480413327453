<template>
  <div class="sceneryCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>景区分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称</span>
      <el-input v-model="list.cateName" placeholder="请输入分类名称"></el-input>
      <span style="margin-right: 20px">状态</span>
      <el-select
        v-model="list.status"
        placeholder="请选择"
        style="margin-right: 20px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getSceneryCateList()">查找</el-button>
      <el-button
        type="primary"
        @click="onAddCate(0)"
        v-if="$store.state.powerList.indexOf('scenery:type:list:insert') !== -1"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="cateId" label="分类ID">
      </el-table-column>
      <el-table-column align="center" prop="cateName" label="分类名称">
      </el-table-column>
      <el-table-column align="center" prop="showImg" label="分类图片">
        <template slot-scope="{ row }">
          <el-image
            class="showimg"
            :preview-src-list="[row.showImg]"
            :src="row.showImg"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="addTime" label="最后修改时间">
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态">
      </el-table-column>
      <el-table-column align="center" prop="" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            v-if="row.status === 2"
            type="primary"
            @click="changeStatus(row.cateId, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            v-if="row.status === 1"
            type="primary"
            @click="changeStatus(row.cateId, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="delCate(row)"
            v-if="
              $store.state.powerList.indexOf('gift:cate:list:delete') !== -1
            "
            >删除</el-button
          >
          <!-- <el-button
              size="mini"
              type="primary"
              @click="$router.push(`/secondSceneryCateList/${row.id}`)"
              >查看下级</el-button
            > -->
          <el-button
            size="mini"
            type="primary"
            @click="onEditCalsssA(row)"
            v-if="$store.state.powerList.indexOf('gift:cate:list:add') !== -1"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="viewLog(row.cateId)"
            v-if="$store.state.powerList.indexOf('gift:cate:list:log') !== -1"
            >查看日志</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="添加分类"
      :visible.sync="addClassADialogVisible"
      width="30%"
      class="addDialog"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            v-model="addClassAForm.cateName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="addClassADialogVisible"
            type="one"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
        </el-form-item>
        <el-form-item
          ><span>每张图片不能超过500K，建议尺寸：256*124px</span></el-form-item
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassADialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddCateClassAOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑分类"
      :visible.sync="editClassADialogVisible"
      width="30%"
      class="editDialog"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input
            v-model="editClassAForm.cateName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <send-image
            v-if="editClassADialogVisible"
            :images="editClassAForm.showImg"
            type="one"
            @addImageStr="(e) => addImageStr(e, 2)"
          ></send-image>
        </el-form-item>
        <el-form-item
          ><span>每张图片不能超过500K，建议尺寸：256*124px</span></el-form-item
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClassADialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditCateClassAOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看日志"
      :visible.sync="viewLogDialogVisible"
      width="30%"
      class="editDialog"
    >
      <div v-for="(item, index) in logList" :key="index" class="log">
        {{ item }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewLogDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  <script>
//   import {
//     sceneryCate,
//     addSceneryCate,
//     changeSort,
//     editSceneryCate,
//     delSceneryCate,
//     changeStatus,
//   } from "../../../api/sceneryCate";
import {
  getGiftCateList,
  addGiftCate,
  modifyDeptGiftCateDept,
  modifyGiftCateStatus,
  deleteGiftCate,
  selectLogGiftCate,
} from "../../../api/seckillInfo";
//   import { sendFile } from "../../api/sendFile";
import SendImage from "../../../components/sendImage.vue";
export default {
  name: "sceneryCateList",
  components: {
    SendImage,
  },
  data() {
    return {
      addClassADialogVisible: false,
      editClassADialogVisible: false,
      addClassAForm: {
        cateName: "",
        showImg: "",
        cateId: 0,
      },
      editClassAForm: {
        cateName: "",
        cateId: null,
        showImg: "",
      },
      list: {
        currentPage: 1,
        pageSize: 5,
        cateName: "",
        status: 0,
      },
      tableData: [],
      pagination: {},
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      logList: [],
      viewLogDialogVisible: false,
    };
  },
  created() {
    this.getSceneryCateList();
  },
  methods: {
    changeStatus(cateId, type) {
      this.$confirm("是否操作这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await modifyGiftCateStatus({
            cateId: cateId,
            status: type,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getSceneryCateList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },

    delCate(row) {
      this.$confirm("是否删除这个分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await deleteGiftCate({
            cateId: row.cateId,
          });
          console.log(data);
          if (data.code == 0) {
            this.getSceneryCateList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async viewLog(cateId) {
      const { data } = await selectLogGiftCate({
        cateId,
      });
      if (data.code == 0) {
        this.logList = data.data;
        this.viewLogDialogVisible = true;
      }
    },
    async onEditCateClassAOk() {
      if (
        this.editClassAForm.cateName === "" ||
        this.editClassAForm.showImg === null ||
        this.editClassAForm.showImg === ""
      ) {
        return this.$message.warning("请把数据填写完整!");
      }
      const { data } = await addGiftCate(this.editClassAForm);
      console.log(data);
      if (data.code === 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        this.getSceneryCateList();
      } else {
      }
      this.editClassADialogVisible = false;
    },
    onEditCalsssA(row) {
      console.log(row);
      this.editClassADialogVisible = true;
      this.editClassAForm.cateName = row.cateName;
      // this.editClassAForm.fatherId = 0;
      this.editClassAForm.cateId = row.cateId;
      this.editClassAForm.showImg = row.showImg;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await modifyDeptGiftCateDept({
        cateId: row.cateId,
        dept: Number(row.dept),
      });
      row.inputVisible = false;
      this.getSceneryCateList();
    },
    async getSceneryCateList() {
      const { data } = await getGiftCateList(this.list);
      this.pagination = data.pagination;
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
    },
    onAddCate(fatherId) {
      this.addClassADialogVisible = true;
      // this.addClassAForm.fatherId = fatherId;
    },
    //上传图片
    addImageStr(image, type) {
      if (type === 1) {
        this.addClassAForm.showImg = image;
      } else if (type === 2) {
        this.editClassAForm.showImg = image;
      }
    },
    async onAddCateClassAOk() {
      if (
        this.addClassAForm.cateName === "" ||
        this.addClassAForm.showImg === ""
      ) {
        return this.$message({
          type: "error",
          message: "请把数据填写完整!",
        });
      }
      const { data } = await addGiftCate(this.addClassAForm);
      console.log(data);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.addClassAForm.cateName = "";
        this.addClassAForm.showImg = "";
      } else {
        this.$message({
          type: "success",
          message: "添加失败,请稍后重试!",
        });
      }
      this.addClassADialogVisible = false;
      this.getSceneryCateList();
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getSceneryCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getSceneryCateList();
    },
  },
};
</script>
  <style lang="less" scoped>
.sceneryCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          td {
            .showimg {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
  }
  /deep/.addDialog,
  /deep/ .editDialog {
    .el-dialog__body {
      .el-form {
        .el-form-item__content {
          display: flex;
          span {
            color: red;
          }
          .img {
            position: relative;
            height: 100px;
            width: 100px;
            margin: 0 20px;
            img {
              height: 100px;
              width: 100px;
              border: 1px solid #c0ccda;
            }
            div {
              position: absolute;
              top: 0;
              right: 5px;
              height: 10px;
              width: 10px;
              z-index: 100;
            }
          }
          .addImg {
            display: inline-block;
            width: 100px;
            height: 100px;
            border: 1px dashed #c0ccda;
            text-align: center;
            line-height: 100px;
            cursor: pointer;
            i {
              width: 50px;
              height: 50px;
              font-size: 20px;
              color: #c0ccda;
            }
          }
        }
      }
    }
    .log{
        margin-top: 20px;
    }
    .log:first-child{
        margin-top:0px
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>